import { Layout } from "../components/Layout";
import React, { useState, useEffect } from "react";
import { Seo } from "@gatsbywpthemes/gatsby-plugin-wp-seo";
import { BarLoader } from "react-spinners";

const ToolsPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleIframeLoad = (event) => {
      if (event.data.type === "iframe-loaded") {
        setLoading(false);
        const iframe = document.getElementById("tools-iframe");
        iframe.style.height = event.data.height + "px";
      }
    };

    window.addEventListener("message", handleIframeLoad);

    return () => {
      window.removeEventListener("message", handleIframeLoad);
    };
  }, []);

  return (
    <Layout location="page" page="tools">
      <Seo title="Free Developer Tools by Codinghelp.io" />
      <article className="text-center bg-[#0f172a]">
        <div className="text-sm font-bold tracking-wider uppercase entry-content">
          {loading && (
            <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-50">
              <BarLoader color="#fff" loading={loading} />
            </div>
          )}
          <div className="iframe-container">
            <iframe
              id="tools-iframe"
              title="Tools"
              src="https://tools.codinghelp.io"
              frameBorder="0"
              style={{
                width: "100%",
                overflow: "hidden",
                border: "none",
                margin: "0",
                padding: "0",
              }}
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </article>
      <style jsx>{`
        .iframe-container {
          display: flex;
          justify-content: center;
        }

        iframe {
          width: 100%;
        }
      `}</style>
    </Layout>
  );
};

export default ToolsPage;
